import { WireMsg, WireType } from "../types";
import { TunnelStats } from "../packetizer/stats";
import {
  ConnectionQuality,
  ConnectionState,
  Participant,
  RemoteParticipant,
  Room,
  Track,
} from "livekit-client-legacy";

export interface ISkybusOptions {
  resetOnClose?: boolean;
  broadcastOnly?: boolean; // used in the livestream viewer case
}

// tslint:disable-next-line: interface-name
export interface ChannelDefinition<T extends WireType<WireMsg>> {
  channel: string;
  type: T;
}

export interface ISkybusStats {
  uploadBytes: number;
  downloadBytes: number;
  errors: number;
}

export interface IConnectionStatus {
  /**
   * Adds a listener for websocket connection status changes
   * @param callback listener for websocket connection status changes
   *
   * @returns a void function to disconnect the listener from receiving updates
   */
  addConnectionStatusListener(callback: (connected: boolean) => void): () => void;
}

// TODO (ryan.fong): update this to livekit's RoomState when kinesis implementation is deprecated
export enum WebRTCConnectionState {
  NEW,
  SIGNALING,
  CONNECTED,
  CLOSED,
}
export interface WebRTCConnectorState {
  clientId: string;
  stats: TunnelStats;
  downloadBitrate: number;
  uploadBitrate: number;
  connectionState: WebRTCConnectionState;
  error?: string;
}

export interface LivekitTransportArgs {
  userUuid: string;
  url: string;
  token: string;
  isTeleop?: boolean;
  handleMultipleParticipants?: () => void;
  onRoomConnect?: (clientId: string, room: Room) => void;
  onRoomDisconnect?: () => void;
  options?: ISkybusOptions;
  vehicleId?: string;
  onDetectedVehicleInRoom?: (vehicleSid: string) => void;
  onDetectedVehicleNotInRoom?: () => void;
  onDetectedDockInRoom?: (dockSid: string) => void;
  onDetectedDockNotInRoom?: () => void;
  onRoomConnectError?: (error: string) => void;
  onRoomReconnecting?: () => void;
  onRoomReconnected?: () => void;
  onConnectionQualityChanged?: (quality: ConnectionQuality, participant: Participant) => void;
  onConnectionStateChanged?: (connectionState: ConnectionState) => void;
  onVideoTrackMapChanged?: (
    videoTrackMap: Map<string, Track>,
    setVideoTracksBySid: Map<string, Map<string, Track>>
  ) => void;
  onParticipantsChanged?: (participants: Map<string, RemoteParticipant> | undefined) => void;
}

export enum RemoteDeviceType {
  VEHICLE = "VEHICLE",
  DOCK = "DOCK",
}

export interface LivekitSkybusStatsReport {
  sendStats: {
    [sid: string]: {
      [channelName: string]: {
        numMessages: number;
        lastMessageTimestamp: number;
        bytes: number;
      };
    };
  };
  receiveStats: {
    [sid: string]: {
      [channelName: string]: {
        numMessages: number;
        lastMessageTimestamp: number;
        bytes: number;
      };
    };
  };
  sendBeforeReadyStats: {
    [sid: string]: {
      [channelName: string]: {
        numMessages: number;
        lastMessageTimestamp: number;
        bytes: number;
      };
    };
  };
}
