// source: pbtypes/util/trajectory_action_planner/trajectory_action_planner.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector3f_pb = require('../../../pbtypes/gen/eigen_lcm/Vector3f_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3f_pb);
var pbtypes_gen_eigen_lcm_Quaternionf_pb = require('../../../pbtypes/gen/eigen_lcm/Quaternionf_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Quaternionf_pb);
var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
var pbtypes_gen_user_camera_photo_capture_result_pb = require('../../../pbtypes/gen/user_camera/photo_capture_result_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_photo_capture_result_pb);
var pbtypes_vehicle_skills_waypoints_pb = require('../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
var pbtypes_gen_user_camera_photo_format_pb = require('../../../pbtypes/gen/user_camera/photo_format_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_photo_format_pb);
var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
goog.exportSymbol('proto.trajectory_action_planner.ActivePhotoCapturesDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.CompletedWaypoint', null, global);
goog.exportSymbol('proto.trajectory_action_planner.CompletedWaypoint.Result', null, global);
goog.exportSymbol('proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.MovementCmdDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PhotoCapture', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PhotoCapture.PhotoType', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PhotoCapture.Result', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PhotoCaptureStateDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PhotoCaptureWaypoint', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanProgress', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanRequest', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanRequest.GlobalMapSource', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanSettings', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanWaypoint', null, global);
goog.exportSymbol('proto.trajectory_action_planner.PlanWaypoint.WaypointCase', null, global);
goog.exportSymbol('proto.trajectory_action_planner.StopForPhotoDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.TrajectoryPlanDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.WaypointStateDebug', null, global);
goog.exportSymbol('proto.trajectory_action_planner.WaypointVelocityLimitsDebug', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.PhotoCaptureWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PhotoCaptureWaypoint.displayName = 'proto.trajectory_action_planner.PhotoCaptureWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PlanWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_);
};
goog.inherits(proto.trajectory_action_planner.PlanWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PlanWaypoint.displayName = 'proto.trajectory_action_planner.PlanWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PlanSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.PlanSettings.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.PlanSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PlanSettings.displayName = 'proto.trajectory_action_planner.PlanSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.PlanRequest.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.PlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PlanRequest.displayName = 'proto.trajectory_action_planner.PlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PhotoCapture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.PhotoCapture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PhotoCapture.displayName = 'proto.trajectory_action_planner.PhotoCapture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.CompletedWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.CompletedWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.CompletedWaypoint.displayName = 'proto.trajectory_action_planner.CompletedWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PlanProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.PlanProgress.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.PlanProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PlanProgress.displayName = 'proto.trajectory_action_planner.PlanProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.WaypointStateDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.WaypointStateDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.WaypointStateDebug.displayName = 'proto.trajectory_action_planner.WaypointStateDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.TrajectoryPlanDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.TrajectoryPlanDebug.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.TrajectoryPlanDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.TrajectoryPlanDebug.displayName = 'proto.trajectory_action_planner.TrajectoryPlanDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.WaypointVelocityLimitsDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.WaypointVelocityLimitsDebug.displayName = 'proto.trajectory_action_planner.WaypointVelocityLimitsDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.MovementCmdDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.MovementCmdDebug.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.MovementCmdDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.MovementCmdDebug.displayName = 'proto.trajectory_action_planner.MovementCmdDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.StopForPhotoDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.StopForPhotoDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.StopForPhotoDebug.displayName = 'proto.trajectory_action_planner.StopForPhotoDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.displayName = 'proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trajectory_action_planner.PhotoCaptureStateDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.PhotoCaptureStateDebug.displayName = 'proto.trajectory_action_planner.PhotoCaptureStateDebug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trajectory_action_planner.ActivePhotoCapturesDebug.repeatedFields_, null);
};
goog.inherits(proto.trajectory_action_planner.ActivePhotoCapturesDebug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trajectory_action_planner.ActivePhotoCapturesDebug.displayName = 'proto.trajectory_action_planner.ActivePhotoCapturesDebug';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PhotoCaptureWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PhotoCaptureWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
lookAtWaypoint: (f = msg.getLookAtWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PhotoCaptureWaypoint}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PhotoCaptureWaypoint;
  return proto.trajectory_action_planner.PhotoCaptureWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PhotoCaptureWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PhotoCaptureWaypoint}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.deserializeBinaryFromReader);
      msg.setLookAtWaypoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PhotoCaptureWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PhotoCaptureWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLookAtWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional skills.LookAtWaypoint look_at_waypoint = 1;
 * @return {?proto.skills.LookAtWaypoint}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.getLookAtWaypoint = function() {
  return /** @type{?proto.skills.LookAtWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint, 1));
};


/**
 * @param {?proto.skills.LookAtWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureWaypoint} returns this
*/
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.setLookAtWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCaptureWaypoint} returns this
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.clearLookAtWaypoint = function() {
  return this.setLookAtWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCaptureWaypoint.prototype.hasLookAtWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.trajectory_action_planner.PlanWaypoint.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.trajectory_action_planner.PlanWaypoint.WaypointCase = {
  WAYPOINT_NOT_SET: 0,
  PHOTO: 2,
  POSITION: 3,
  POSE: 4,
  LOOK_AT: 5
};

/**
 * @return {proto.trajectory_action_planner.PlanWaypoint.WaypointCase}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getWaypointCase = function() {
  return /** @type {proto.trajectory_action_planner.PlanWaypoint.WaypointCase} */(jspb.Message.computeOneofCase(this, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PlanWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PlanWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
photo: (f = msg.getPhoto()) && proto.trajectory_action_planner.PhotoCaptureWaypoint.toObject(includeInstance, f),
position: (f = msg.getPosition()) && pbtypes_vehicle_skills_waypoints_pb.PositionWaypoint.toObject(includeInstance, f),
pose: (f = msg.getPose()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
lookAt: (f = msg.getLookAt()) && pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PlanWaypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PlanWaypoint;
  return proto.trajectory_action_planner.PlanWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PlanWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PlanWaypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.trajectory_action_planner.PhotoCaptureWaypoint;
      reader.readMessage(value,proto.trajectory_action_planner.PhotoCaptureWaypoint.deserializeBinaryFromReader);
      msg.setPhoto(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_waypoints_pb.PositionWaypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.PositionWaypoint.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setPose(value);
      break;
    case 5:
      var value = new pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.deserializeBinaryFromReader);
      msg.setLookAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PlanWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PlanWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getPhoto();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.trajectory_action_planner.PhotoCaptureWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_waypoints_pb.PositionWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getPose();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getLookAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional uuid_util.Uuid uuid = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
*/
proto.trajectory_action_planner.PlanWaypoint.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PhotoCaptureWaypoint photo = 2;
 * @return {?proto.trajectory_action_planner.PhotoCaptureWaypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getPhoto = function() {
  return /** @type{?proto.trajectory_action_planner.PhotoCaptureWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.trajectory_action_planner.PhotoCaptureWaypoint, 2));
};


/**
 * @param {?proto.trajectory_action_planner.PhotoCaptureWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
*/
proto.trajectory_action_planner.PlanWaypoint.prototype.setPhoto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.clearPhoto = function() {
  return this.setPhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.hasPhoto = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional skills.PositionWaypoint position = 3;
 * @return {?proto.skills.PositionWaypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getPosition = function() {
  return /** @type{?proto.skills.PositionWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.PositionWaypoint, 3));
};


/**
 * @param {?proto.skills.PositionWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
*/
proto.trajectory_action_planner.PlanWaypoint.prototype.setPosition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional skills.Waypoint pose = 4;
 * @return {?proto.skills.Waypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getPose = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 4));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
*/
proto.trajectory_action_planner.PlanWaypoint.prototype.setPose = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.clearPose = function() {
  return this.setPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.hasPose = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional skills.LookAtWaypoint look_at = 5;
 * @return {?proto.skills.LookAtWaypoint}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.getLookAt = function() {
  return /** @type{?proto.skills.LookAtWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.LookAtWaypoint, 5));
};


/**
 * @param {?proto.skills.LookAtWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
*/
proto.trajectory_action_planner.PlanWaypoint.prototype.setLookAt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.trajectory_action_planner.PlanWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanWaypoint} returns this
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.clearLookAt = function() {
  return this.setLookAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanWaypoint.prototype.hasLookAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.PlanSettings.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PlanSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PlanSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PlanSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
maxAllowedBlurPixels: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
ignoreTranslationMotionBlurConstraint: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
globalVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
globalAngularVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
globalMinimumWaypointVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
takeBestEffortPhotoWhenFailing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
eoPhotoFormatsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
irPhotoFormatsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
eoJpegQuality: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PlanSettings}
 */
proto.trajectory_action_planner.PlanSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PlanSettings;
  return proto.trajectory_action_planner.PlanSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PlanSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PlanSettings}
 */
proto.trajectory_action_planner.PlanSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxAllowedBlurPixels(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreTranslationMotionBlurConstraint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGlobalVelocityLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGlobalAngularVelocityLimit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGlobalMinimumWaypointVelocity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTakeBestEffortPhotoWhenFailing(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.user_camera.PhotoFormat.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEoPhotoFormats(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.user_camera.PhotoFormat.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIrPhotoFormats(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEoJpegQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PlanSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PlanSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PlanSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxAllowedBlurPixels();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getIgnoreTranslationMotionBlurConstraint();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGlobalVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getGlobalAngularVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getGlobalMinimumWaypointVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTakeBestEffortPhotoWhenFailing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEoPhotoFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getIrPhotoFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getEoJpegQuality();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional float max_allowed_blur_pixels = 1;
 * @return {number}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getMaxAllowedBlurPixels = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setMaxAllowedBlurPixels = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool ignore_translation_motion_blur_constraint = 5;
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getIgnoreTranslationMotionBlurConstraint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setIgnoreTranslationMotionBlurConstraint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float global_velocity_limit = 2;
 * @return {number}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getGlobalVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setGlobalVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float global_angular_velocity_limit = 3;
 * @return {number}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getGlobalAngularVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setGlobalAngularVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float global_minimum_waypoint_velocity = 9;
 * @return {number}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getGlobalMinimumWaypointVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setGlobalMinimumWaypointVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional bool take_best_effort_photo_when_failing = 4;
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getTakeBestEffortPhotoWhenFailing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setTakeBestEffortPhotoWhenFailing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated user_camera.PhotoFormat.Enum eo_photo_formats = 6;
 * @return {!Array<!proto.user_camera.PhotoFormat.Enum>}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getEoPhotoFormatsList = function() {
  return /** @type {!Array<!proto.user_camera.PhotoFormat.Enum>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.user_camera.PhotoFormat.Enum>} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setEoPhotoFormatsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.addEoPhotoFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.clearEoPhotoFormatsList = function() {
  return this.setEoPhotoFormatsList([]);
};


/**
 * repeated user_camera.PhotoFormat.Enum ir_photo_formats = 7;
 * @return {!Array<!proto.user_camera.PhotoFormat.Enum>}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getIrPhotoFormatsList = function() {
  return /** @type {!Array<!proto.user_camera.PhotoFormat.Enum>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.user_camera.PhotoFormat.Enum>} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setIrPhotoFormatsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.addIrPhotoFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.clearIrPhotoFormatsList = function() {
  return this.setIrPhotoFormatsList([]);
};


/**
 * optional uint32 eo_jpeg_quality = 8;
 * @return {number}
 */
proto.trajectory_action_planner.PlanSettings.prototype.getEoJpegQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanSettings} returns this
 */
proto.trajectory_action_planner.PlanSettings.prototype.setEoJpegQuality = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.PlanRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
settings: (f = msg.getSettings()) && proto.trajectory_action_planner.PlanSettings.toObject(includeInstance, f),
waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.trajectory_action_planner.PlanWaypoint.toObject, includeInstance),
frame: jspb.Message.getFieldWithDefault(msg, 6, 0),
globalMapSource: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PlanRequest}
 */
proto.trajectory_action_planner.PlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PlanRequest;
  return proto.trajectory_action_planner.PlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PlanRequest}
 */
proto.trajectory_action_planner.PlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 3:
      var value = new proto.trajectory_action_planner.PlanSettings;
      reader.readMessage(value,proto.trajectory_action_planner.PlanSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 4:
      var value = new proto.trajectory_action_planner.PlanWaypoint;
      reader.readMessage(value,proto.trajectory_action_planner.PlanWaypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    case 6:
      var value = /** @type {!proto.skills.FrameType.Enum} */ (reader.readEnum());
      msg.setFrame(value);
      break;
    case 5:
      var value = /** @type {!proto.trajectory_action_planner.PlanRequest.GlobalMapSource} */ (reader.readEnum());
      msg.setGlobalMapSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.trajectory_action_planner.PlanSettings.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.trajectory_action_planner.PlanWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getGlobalMapSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.trajectory_action_planner.PlanRequest.GlobalMapSource = {
  UNKNOWN: 0,
  VISION: 1,
  GPS: 2
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
*/
proto.trajectory_action_planner.PlanRequest.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanRequest.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PlanSettings settings = 3;
 * @return {?proto.trajectory_action_planner.PlanSettings}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getSettings = function() {
  return /** @type{?proto.trajectory_action_planner.PlanSettings} */ (
    jspb.Message.getWrapperField(this, proto.trajectory_action_planner.PlanSettings, 3));
};


/**
 * @param {?proto.trajectory_action_planner.PlanSettings|undefined} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
*/
proto.trajectory_action_planner.PlanRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PlanWaypoint waypoints = 4;
 * @return {!Array<!proto.trajectory_action_planner.PlanWaypoint>}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.PlanWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.PlanWaypoint, 4));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.PlanWaypoint>} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
*/
proto.trajectory_action_planner.PlanRequest.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.trajectory_action_planner.PlanWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PlanWaypoint}
 */
proto.trajectory_action_planner.PlanRequest.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.trajectory_action_planner.PlanWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};


/**
 * optional skills.FrameType.Enum frame = 6;
 * @return {!proto.skills.FrameType.Enum}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getFrame = function() {
  return /** @type {!proto.skills.FrameType.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skills.FrameType.Enum} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.setFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional GlobalMapSource global_map_source = 5;
 * @return {!proto.trajectory_action_planner.PlanRequest.GlobalMapSource}
 */
proto.trajectory_action_planner.PlanRequest.prototype.getGlobalMapSource = function() {
  return /** @type {!proto.trajectory_action_planner.PlanRequest.GlobalMapSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.trajectory_action_planner.PlanRequest.GlobalMapSource} value
 * @return {!proto.trajectory_action_planner.PlanRequest} returns this
 */
proto.trajectory_action_planner.PlanRequest.prototype.setGlobalMapSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PhotoCapture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PhotoCapture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCapture.toObject = function(includeInstance, msg) {
  var f, obj = {
waypointUuid: (f = msg.getWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
result: jspb.Message.getFieldWithDefault(msg, 2, 0),
userCameraResult: jspb.Message.getFieldWithDefault(msg, 3, 0),
photoWaypoint: (f = msg.getPhotoWaypoint()) && proto.trajectory_action_planner.PlanWaypoint.toObject(includeInstance, f),
referenceTPhotoRdf: (f = msg.getReferenceTPhotoRdf()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
gimbalImuVelocityLimitRdf: (f = msg.getGimbalImuVelocityLimitRdf()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gimbalImuAngularVelocityLimitRdf: (f = msg.getGimbalImuAngularVelocityLimitRdf()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gimbalImuVelocityAtPhotoCaptureRdf: (f = msg.getGimbalImuVelocityAtPhotoCaptureRdf()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gimbalImuAngularVelocityAtPhotoCaptureRdf: (f = msg.getGimbalImuAngularVelocityAtPhotoCaptureRdf()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gimbalImuTWaypointRdf: (f = msg.getGimbalImuTWaypointRdf()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gimbalImuRWaypointRdf: (f = msg.getGimbalImuRWaypointRdf()) && pbtypes_gen_eigen_lcm_Quaternionf_pb.QuaternionfPb.toObject(includeInstance, f),
photoFilePath: jspb.Message.getFieldWithDefault(msg, 14, ""),
photoType: jspb.Message.getFieldWithDefault(msg, 15, 0),
format: jspb.Message.getFieldWithDefault(msg, 16, 0),
mediaId: jspb.Message.getFieldWithDefault(msg, 17, ""),
photoCaptureUtime: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PhotoCapture}
 */
proto.trajectory_action_planner.PhotoCapture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PhotoCapture;
  return proto.trajectory_action_planner.PhotoCapture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PhotoCapture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PhotoCapture}
 */
proto.trajectory_action_planner.PhotoCapture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setWaypointUuid(value);
      break;
    case 2:
      var value = /** @type {!proto.trajectory_action_planner.PhotoCapture.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {!proto.user_camera.PhotoCaptureResult.Enum} */ (reader.readEnum());
      msg.setUserCameraResult(value);
      break;
    case 4:
      var value = new proto.trajectory_action_planner.PlanWaypoint;
      reader.readMessage(value,proto.trajectory_action_planner.PlanWaypoint.deserializeBinaryFromReader);
      msg.setPhotoWaypoint(value);
      break;
    case 19:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setReferenceTPhotoRdf(value);
      break;
    case 6:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGimbalImuVelocityLimitRdf(value);
      break;
    case 7:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGimbalImuAngularVelocityLimitRdf(value);
      break;
    case 8:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGimbalImuVelocityAtPhotoCaptureRdf(value);
      break;
    case 9:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGimbalImuAngularVelocityAtPhotoCaptureRdf(value);
      break;
    case 10:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGimbalImuTWaypointRdf(value);
      break;
    case 11:
      var value = new pbtypes_gen_eigen_lcm_Quaternionf_pb.QuaternionfPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Quaternionf_pb.QuaternionfPb.deserializeBinaryFromReader);
      msg.setGimbalImuRWaypointRdf(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoFilePath(value);
      break;
    case 15:
      var value = /** @type {!proto.trajectory_action_planner.PhotoCapture.PhotoType} */ (reader.readEnum());
      msg.setPhotoType(value);
      break;
    case 16:
      var value = /** @type {!proto.user_camera.PhotoFormat.Enum} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhotoCaptureUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PhotoCapture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PhotoCapture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCapture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserCameraResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPhotoWaypoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.trajectory_action_planner.PlanWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getReferenceTPhotoRdf();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuVelocityLimitRdf();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuAngularVelocityLimitRdf();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuVelocityAtPhotoCaptureRdf();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuAngularVelocityAtPhotoCaptureRdf();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuTWaypointRdf();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuRWaypointRdf();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_gen_eigen_lcm_Quaternionf_pb.QuaternionfPb.serializeBinaryToWriter
    );
  }
  f = message.getPhotoFilePath();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPhotoType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getMediaId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPhotoCaptureUtime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.trajectory_action_planner.PhotoCapture.Result = {
  UNKNOWN: 0,
  SUCCESS: 1,
  SUCCESS_LIMITS_EXCEEDED: 2,
  SUCCESS_BEST_EFFORT_PHOTO: 3,
  FAILURE_ISP_ERROR: 4,
  FAILURE_GAVE_UP_REACHING_WAYPOINT: 5,
  FAILURE_ISP_TIMEOUT: 6
};

/**
 * @enum {number}
 */
proto.trajectory_action_planner.PhotoCapture.PhotoType = {
  EO: 0,
  IR: 1
};

/**
 * optional uuid_util.Uuid waypoint_uuid = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearWaypointUuid = function() {
  return this.setWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasWaypointUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Result result = 2;
 * @return {!proto.trajectory_action_planner.PhotoCapture.Result}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getResult = function() {
  return /** @type {!proto.trajectory_action_planner.PhotoCapture.Result} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.trajectory_action_planner.PhotoCapture.Result} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional user_camera.PhotoCaptureResult.Enum user_camera_result = 3;
 * @return {!proto.user_camera.PhotoCaptureResult.Enum}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getUserCameraResult = function() {
  return /** @type {!proto.user_camera.PhotoCaptureResult.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.user_camera.PhotoCaptureResult.Enum} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setUserCameraResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PlanWaypoint photo_waypoint = 4;
 * @return {?proto.trajectory_action_planner.PlanWaypoint}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getPhotoWaypoint = function() {
  return /** @type{?proto.trajectory_action_planner.PlanWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.trajectory_action_planner.PlanWaypoint, 4));
};


/**
 * @param {?proto.trajectory_action_planner.PlanWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setPhotoWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearPhotoWaypoint = function() {
  return this.setPhotoWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasPhotoWaypoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional body.TransPb reference_T_photo_RDF = 19;
 * @return {?proto.body.TransPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getReferenceTPhotoRdf = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 19));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setReferenceTPhotoRdf = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearReferenceTPhotoRdf = function() {
  return this.setReferenceTPhotoRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasReferenceTPhotoRdf = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional eigen_lcm.Vector3fPb gimbal_imu_velocity_limit_RDF = 6;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuVelocityLimitRdf = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 6));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuVelocityLimitRdf = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuVelocityLimitRdf = function() {
  return this.setGimbalImuVelocityLimitRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuVelocityLimitRdf = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional eigen_lcm.Vector3fPb gimbal_imu_angular_velocity_limit_RDF = 7;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuAngularVelocityLimitRdf = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 7));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuAngularVelocityLimitRdf = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuAngularVelocityLimitRdf = function() {
  return this.setGimbalImuAngularVelocityLimitRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuAngularVelocityLimitRdf = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional eigen_lcm.Vector3fPb gimbal_imu_velocity_at_photo_capture_RDF = 8;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuVelocityAtPhotoCaptureRdf = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 8));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuVelocityAtPhotoCaptureRdf = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuVelocityAtPhotoCaptureRdf = function() {
  return this.setGimbalImuVelocityAtPhotoCaptureRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuVelocityAtPhotoCaptureRdf = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional eigen_lcm.Vector3fPb gimbal_imu_angular_velocity_at_photo_capture_RDF = 9;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuAngularVelocityAtPhotoCaptureRdf = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 9));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuAngularVelocityAtPhotoCaptureRdf = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuAngularVelocityAtPhotoCaptureRdf = function() {
  return this.setGimbalImuAngularVelocityAtPhotoCaptureRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuAngularVelocityAtPhotoCaptureRdf = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional eigen_lcm.Vector3fPb gimbal_imu_t_waypoint_RDF = 10;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuTWaypointRdf = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 10));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuTWaypointRdf = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuTWaypointRdf = function() {
  return this.setGimbalImuTWaypointRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuTWaypointRdf = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional eigen_lcm.QuaternionfPb gimbal_imu_r_waypoint_RDF = 11;
 * @return {?proto.eigen_lcm.QuaternionfPb}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getGimbalImuRWaypointRdf = function() {
  return /** @type{?proto.eigen_lcm.QuaternionfPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Quaternionf_pb.QuaternionfPb, 11));
};


/**
 * @param {?proto.eigen_lcm.QuaternionfPb|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
*/
proto.trajectory_action_planner.PhotoCapture.prototype.setGimbalImuRWaypointRdf = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.clearGimbalImuRWaypointRdf = function() {
  return this.setGimbalImuRWaypointRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.hasGimbalImuRWaypointRdf = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string photo_file_path = 14;
 * @return {string}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getPhotoFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setPhotoFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional PhotoType photo_type = 15;
 * @return {!proto.trajectory_action_planner.PhotoCapture.PhotoType}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getPhotoType = function() {
  return /** @type {!proto.trajectory_action_planner.PhotoCapture.PhotoType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.trajectory_action_planner.PhotoCapture.PhotoType} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setPhotoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional user_camera.PhotoFormat.Enum format = 16;
 * @return {!proto.user_camera.PhotoFormat.Enum}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getFormat = function() {
  return /** @type {!proto.user_camera.PhotoFormat.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string media_id = 17;
 * @return {string}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 photo_capture_utime = 18;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCapture.prototype.getPhotoCaptureUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCapture} returns this
 */
proto.trajectory_action_planner.PhotoCapture.prototype.setPhotoCaptureUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.CompletedWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.CompletedWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.CompletedWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointUuid: (f = msg.getWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
result: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.CompletedWaypoint}
 */
proto.trajectory_action_planner.CompletedWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.CompletedWaypoint;
  return proto.trajectory_action_planner.CompletedWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.CompletedWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.CompletedWaypoint}
 */
proto.trajectory_action_planner.CompletedWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setWaypointUuid(value);
      break;
    case 3:
      var value = /** @type {!proto.trajectory_action_planner.CompletedWaypoint.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.CompletedWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.CompletedWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.CompletedWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.trajectory_action_planner.CompletedWaypoint.Result = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILURE_GAVE_UP_REACHING_WAYPOINT: 2
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.CompletedWaypoint} returns this
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid waypoint_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.getWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.CompletedWaypoint} returns this
*/
proto.trajectory_action_planner.CompletedWaypoint.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.CompletedWaypoint} returns this
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.clearWaypointUuid = function() {
  return this.setWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.hasWaypointUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 3;
 * @return {!proto.trajectory_action_planner.CompletedWaypoint.Result}
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.getResult = function() {
  return /** @type {!proto.trajectory_action_planner.CompletedWaypoint.Result} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.trajectory_action_planner.CompletedWaypoint.Result} value
 * @return {!proto.trajectory_action_planner.CompletedWaypoint} returns this
 */
proto.trajectory_action_planner.CompletedWaypoint.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.PlanProgress.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PlanProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PlanProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PlanProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
planUuid: (f = msg.getPlanUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
isComplete: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
completedWaypointsList: jspb.Message.toObjectList(msg.getCompletedWaypointsList(),
    proto.trajectory_action_planner.CompletedWaypoint.toObject, includeInstance),
photoCapturesList: jspb.Message.toObjectList(msg.getPhotoCapturesList(),
    proto.trajectory_action_planner.PhotoCapture.toObject, includeInstance),
estimatedTimeToCompletionS: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PlanProgress}
 */
proto.trajectory_action_planner.PlanProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PlanProgress;
  return proto.trajectory_action_planner.PlanProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PlanProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PlanProgress}
 */
proto.trajectory_action_planner.PlanProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setPlanUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsComplete(value);
      break;
    case 4:
      var value = new proto.trajectory_action_planner.CompletedWaypoint;
      reader.readMessage(value,proto.trajectory_action_planner.CompletedWaypoint.deserializeBinaryFromReader);
      msg.addCompletedWaypoints(value);
      break;
    case 5:
      var value = new proto.trajectory_action_planner.PhotoCapture;
      reader.readMessage(value,proto.trajectory_action_planner.PhotoCapture.deserializeBinaryFromReader);
      msg.addPhotoCaptures(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedTimeToCompletionS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PlanProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PlanProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PlanProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PlanProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlanUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getIsComplete();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCompletedWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.trajectory_action_planner.CompletedWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getPhotoCapturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.trajectory_action_planner.PhotoCapture.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTimeToCompletionS();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid plan_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getPlanUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
*/
proto.trajectory_action_planner.PlanProgress.prototype.setPlanUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.clearPlanUuid = function() {
  return this.setPlanUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanProgress.prototype.hasPlanUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_complete = 3;
 * @return {boolean}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getIsComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.setIsComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated CompletedWaypoint completed_waypoints = 4;
 * @return {!Array<!proto.trajectory_action_planner.CompletedWaypoint>}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getCompletedWaypointsList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.CompletedWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.CompletedWaypoint, 4));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.CompletedWaypoint>} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
*/
proto.trajectory_action_planner.PlanProgress.prototype.setCompletedWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.trajectory_action_planner.CompletedWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.CompletedWaypoint}
 */
proto.trajectory_action_planner.PlanProgress.prototype.addCompletedWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.trajectory_action_planner.CompletedWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.clearCompletedWaypointsList = function() {
  return this.setCompletedWaypointsList([]);
};


/**
 * repeated PhotoCapture photo_captures = 5;
 * @return {!Array<!proto.trajectory_action_planner.PhotoCapture>}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getPhotoCapturesList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.PhotoCapture>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.PhotoCapture, 5));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.PhotoCapture>} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
*/
proto.trajectory_action_planner.PlanProgress.prototype.setPhotoCapturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.trajectory_action_planner.PhotoCapture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PhotoCapture}
 */
proto.trajectory_action_planner.PlanProgress.prototype.addPhotoCaptures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.trajectory_action_planner.PhotoCapture, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.clearPhotoCapturesList = function() {
  return this.setPhotoCapturesList([]);
};


/**
 * optional float estimated_time_to_completion_s = 6;
 * @return {number}
 */
proto.trajectory_action_planner.PlanProgress.prototype.getEstimatedTimeToCompletionS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PlanProgress} returns this
 */
proto.trajectory_action_planner.PlanProgress.prototype.setEstimatedTimeToCompletionS = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.WaypointStateDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.WaypointStateDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.WaypointStateDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && proto.trajectory_action_planner.PlanWaypoint.toObject(includeInstance, f),
isPhotoWaypoint: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
stopAtWaypoint: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
stopAtWaypointTimeoutStartUtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
pathVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
linearVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
angularVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
estimatedTimeToReachWaypoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.WaypointStateDebug}
 */
proto.trajectory_action_planner.WaypointStateDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.WaypointStateDebug;
  return proto.trajectory_action_planner.WaypointStateDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.WaypointStateDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.WaypointStateDebug}
 */
proto.trajectory_action_planner.WaypointStateDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trajectory_action_planner.PlanWaypoint;
      reader.readMessage(value,proto.trajectory_action_planner.PlanWaypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPhotoWaypoint(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopAtWaypoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStopAtWaypointTimeoutStartUtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPathVelocityLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLinearVelocityLimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAngularVelocityLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEstimatedTimeToReachWaypoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.WaypointStateDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.WaypointStateDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.WaypointStateDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.trajectory_action_planner.PlanWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getIsPhotoWaypoint();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getStopAtWaypoint();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStopAtWaypointTimeoutStartUtime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPathVelocityLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLinearVelocityLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAngularVelocityLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getEstimatedTimeToReachWaypoint();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional PlanWaypoint waypoint = 1;
 * @return {?proto.trajectory_action_planner.PlanWaypoint}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getWaypoint = function() {
  return /** @type{?proto.trajectory_action_planner.PlanWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.trajectory_action_planner.PlanWaypoint, 1));
};


/**
 * @param {?proto.trajectory_action_planner.PlanWaypoint|undefined} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
*/
proto.trajectory_action_planner.WaypointStateDebug.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_photo_waypoint = 2;
 * @return {boolean}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getIsPhotoWaypoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setIsPhotoWaypoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool stop_at_waypoint = 3;
 * @return {boolean}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getStopAtWaypoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setStopAtWaypoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint64 stop_at_waypoint_timeout_start_utime = 4;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getStopAtWaypointTimeoutStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setStopAtWaypointTimeoutStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double path_velocity_limit = 5;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getPathVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setPathVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double linear_velocity_limit = 6;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getLinearVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setLinearVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double angular_velocity_limit = 7;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getAngularVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setAngularVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double estimated_time_to_reach_waypoint = 8;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.getEstimatedTimeToReachWaypoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointStateDebug} returns this
 */
proto.trajectory_action_planner.WaypointStateDebug.prototype.setEstimatedTimeToReachWaypoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.TrajectoryPlanDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.TrajectoryPlanDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
planUuid: (f = msg.getPlanUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
navTReference: (f = msg.getNavTReference()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.trajectory_action_planner.WaypointStateDebug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.TrajectoryPlanDebug;
  return proto.trajectory_action_planner.TrajectoryPlanDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.TrajectoryPlanDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setPlanUuid(value);
      break;
    case 3:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setNavTReference(value);
      break;
    case 4:
      var value = new proto.trajectory_action_planner.WaypointStateDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointStateDebug.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.TrajectoryPlanDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.TrajectoryPlanDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlanUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getNavTReference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.trajectory_action_planner.WaypointStateDebug.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid plan_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.getPlanUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
*/
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.setPlanUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.clearPlanUuid = function() {
  return this.setPlanUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.hasPlanUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional body.TransPb nav_T_reference = 3;
 * @return {?proto.body.TransPb}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.getNavTReference = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 3));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
*/
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.setNavTReference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.clearNavTReference = function() {
  return this.setNavTReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.hasNavTReference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated WaypointStateDebug waypoints = 4;
 * @return {!Array<!proto.trajectory_action_planner.WaypointStateDebug>}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointStateDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointStateDebug, 4));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointStateDebug>} value
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
*/
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointStateDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointStateDebug}
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.trajectory_action_planner.WaypointStateDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.TrajectoryPlanDebug} returns this
 */
proto.trajectory_action_planner.TrajectoryPlanDebug.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
pathVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
linearVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
angularVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
  return proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPathVelocityLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLinearVelocityLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngularVelocityLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPathVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLinearVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAngularVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float path_velocity_limit = 1;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.getPathVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} returns this
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.setPathVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float linear_velocity_limit = 2;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.getLinearVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} returns this
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.setLinearVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float angular_velocity_limit = 3;
 * @return {number}
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.getAngularVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug} returns this
 */
proto.trajectory_action_planner.WaypointVelocityLimitsDebug.prototype.setAngularVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.MovementCmdDebug.repeatedFields_ = [16,17,18,19,20,23,22,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.MovementCmdDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.MovementCmdDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.MovementCmdDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
navTVehicle: (f = msg.getNavTVehicle()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
targetWaypointUuid: (f = msg.getTargetWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
distanceToTargetWaypointM: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
timeToTargetWaypointS: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
currentHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
currentHeadingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
currentPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
hasGimbalTarget: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
targetWaypointHeadingTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
targetWaypointHeadingDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
targetWaypointPitchTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
holdForPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
slowForPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
waypointsInWindow: jspb.Message.getFieldWithDefault(msg, 15, 0),
waypointUuidsList: jspb.Message.toObjectList(msg.getWaypointUuidsList(),
    pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject, includeInstance),
waypointLimitsInitialList: jspb.Message.toObjectList(msg.getWaypointLimitsInitialList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsPhotoBlurList: jspb.Message.toObjectList(msg.getWaypointLimitsPhotoBlurList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsRotateForPhotoList: jspb.Message.toObjectList(msg.getWaypointLimitsRotateForPhotoList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsCaptureFrequencyList: jspb.Message.toObjectList(msg.getWaypointLimitsCaptureFrequencyList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsSlowForPhotoList: jspb.Message.toObjectList(msg.getWaypointLimitsSlowForPhotoList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsHoldForPhotoList: jspb.Message.toObjectList(msg.getWaypointLimitsHoldForPhotoList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance),
waypointLimitsStaticList: jspb.Message.toObjectList(msg.getWaypointLimitsStaticList(),
    proto.trajectory_action_planner.WaypointVelocityLimitsDebug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.MovementCmdDebug;
  return proto.trajectory_action_planner.MovementCmdDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.MovementCmdDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setNavTVehicle(value);
      break;
    case 3:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setTargetWaypointUuid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceToTargetWaypointM(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeToTargetWaypointS(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentHeading(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentHeadingRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentPitch(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGimbalTarget(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTargetWaypointHeadingTarget(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTargetWaypointHeadingDiff(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTargetWaypointPitchTarget(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoldForPhoto(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowForPhoto(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWaypointsInWindow(value);
      break;
    case 16:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.addWaypointUuids(value);
      break;
    case 17:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsInitial(value);
      break;
    case 18:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsPhotoBlur(value);
      break;
    case 19:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsRotateForPhoto(value);
      break;
    case 20:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsCaptureFrequency(value);
      break;
    case 23:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsSlowForPhoto(value);
      break;
    case 22:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsHoldForPhoto(value);
      break;
    case 21:
      var value = new proto.trajectory_action_planner.WaypointVelocityLimitsDebug;
      reader.readMessage(value,proto.trajectory_action_planner.WaypointVelocityLimitsDebug.deserializeBinaryFromReader);
      msg.addWaypointLimitsStatic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.MovementCmdDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.MovementCmdDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.MovementCmdDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNavTVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getTargetWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getDistanceToTargetWaypointM();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTimeToTargetWaypointS();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCurrentHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getCurrentHeadingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getCurrentPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getHasGimbalTarget();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTargetWaypointHeadingTarget();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTargetWaypointHeadingDiff();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getTargetWaypointPitchTarget();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getHoldForPhoto();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSlowForPhoto();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getWaypointsInWindow();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getWaypointUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsInitialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsPhotoBlurList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsRotateForPhotoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsCaptureFrequencyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsSlowForPhotoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsHoldForPhotoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLimitsStaticList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.trajectory_action_planner.WaypointVelocityLimitsDebug.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional eigen_lcm.Vector3fPb nav_t_vehicle = 2;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getNavTVehicle = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 2));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setNavTVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearNavTVehicle = function() {
  return this.setNavTVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.hasNavTVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uuid_util.Uuid target_waypoint_uuid = 3;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getTargetWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 3));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setTargetWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearTargetWaypointUuid = function() {
  return this.setTargetWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.hasTargetWaypointUuid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float distance_to_target_waypoint_m = 4;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getDistanceToTargetWaypointM = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setDistanceToTargetWaypointM = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float time_to_target_waypoint_s = 5;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getTimeToTargetWaypointS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setTimeToTargetWaypointS = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float current_heading = 6;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getCurrentHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setCurrentHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float current_heading_rate = 7;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getCurrentHeadingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setCurrentHeadingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float current_pitch = 8;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getCurrentPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setCurrentPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool has_gimbal_target = 9;
 * @return {boolean}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getHasGimbalTarget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setHasGimbalTarget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional float target_waypoint_heading_target = 10;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getTargetWaypointHeadingTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setTargetWaypointHeadingTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float target_waypoint_heading_diff = 11;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getTargetWaypointHeadingDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setTargetWaypointHeadingDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float target_waypoint_pitch_target = 12;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getTargetWaypointPitchTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setTargetWaypointPitchTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional bool hold_for_photo = 13;
 * @return {boolean}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getHoldForPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setHoldForPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool slow_for_photo = 14;
 * @return {boolean}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getSlowForPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setSlowForPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional uint64 waypoints_in_window = 15;
 * @return {number}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointsInWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointsInWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated uuid_util.Uuid waypoint_uuids = 16;
 * @return {!Array<!proto.uuid_util.Uuid>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointUuidsList = function() {
  return /** @type{!Array<!proto.uuid_util.Uuid>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 16));
};


/**
 * @param {!Array<!proto.uuid_util.Uuid>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointUuidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.uuid_util.Uuid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointUuids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.uuid_util.Uuid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointUuidsList = function() {
  return this.setWaypointUuidsList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_initial = 17;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsInitialList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 17));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsInitialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsInitial = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsInitialList = function() {
  return this.setWaypointLimitsInitialList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_photo_blur = 18;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsPhotoBlurList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 18));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsPhotoBlurList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsPhotoBlur = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsPhotoBlurList = function() {
  return this.setWaypointLimitsPhotoBlurList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_rotate_for_photo = 19;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsRotateForPhotoList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 19));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsRotateForPhotoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsRotateForPhoto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsRotateForPhotoList = function() {
  return this.setWaypointLimitsRotateForPhotoList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_capture_frequency = 20;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsCaptureFrequencyList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 20));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsCaptureFrequencyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsCaptureFrequency = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsCaptureFrequencyList = function() {
  return this.setWaypointLimitsCaptureFrequencyList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_slow_for_photo = 23;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsSlowForPhotoList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 23));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsSlowForPhotoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsSlowForPhoto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsSlowForPhotoList = function() {
  return this.setWaypointLimitsSlowForPhotoList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_hold_for_photo = 22;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsHoldForPhotoList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 22));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsHoldForPhotoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsHoldForPhoto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsHoldForPhotoList = function() {
  return this.setWaypointLimitsHoldForPhotoList([]);
};


/**
 * repeated WaypointVelocityLimitsDebug waypoint_limits_static = 21;
 * @return {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.getWaypointLimitsStaticList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, 21));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.WaypointVelocityLimitsDebug>} value
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
*/
proto.trajectory_action_planner.MovementCmdDebug.prototype.setWaypointLimitsStaticList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.WaypointVelocityLimitsDebug}
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.addWaypointLimitsStatic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.trajectory_action_planner.WaypointVelocityLimitsDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.MovementCmdDebug} returns this
 */
proto.trajectory_action_planner.MovementCmdDebug.prototype.clearWaypointLimitsStaticList = function() {
  return this.setWaypointLimitsStaticList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.StopForPhotoDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.StopForPhotoDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.StopForPhotoDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointUuid: (f = msg.getWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
navTVehicle: (f = msg.getNavTVehicle()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
navTWaypoint: (f = msg.getNavTWaypoint()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
vehiclePitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
waypointPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
pitchDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
vehicleHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
waypointHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
headingDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
hasReachedWaypoint: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
hasReachedVelocityLimit: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
readyForNextPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
currentTimeoutDuration: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
timedOut: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug}
 */
proto.trajectory_action_planner.StopForPhotoDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.StopForPhotoDebug;
  return proto.trajectory_action_planner.StopForPhotoDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.StopForPhotoDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug}
 */
proto.trajectory_action_planner.StopForPhotoDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setWaypointUuid(value);
      break;
    case 3:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setNavTVehicle(value);
      break;
    case 4:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setNavTWaypoint(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVehiclePitch(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaypointPitch(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPitchDiff(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVehicleHeading(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaypointHeading(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeadingDiff(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasReachedWaypoint(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasReachedVelocityLimit(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadyForNextPhoto(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentTimeoutDuration(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTimedOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.StopForPhotoDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.StopForPhotoDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.StopForPhotoDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getNavTVehicle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getNavTWaypoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getVehiclePitch();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getWaypointPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getPitchDiff();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getVehicleHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getWaypointHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getHeadingDiff();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getHasReachedWaypoint();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHasReachedVelocityLimit();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getReadyForNextPhoto();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCurrentTimeoutDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getTimedOut();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid waypoint_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
*/
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.clearWaypointUuid = function() {
  return this.setWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.hasWaypointUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional eigen_lcm.Vector3fPb nav_t_vehicle = 3;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getNavTVehicle = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 3));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
*/
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setNavTVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.clearNavTVehicle = function() {
  return this.setNavTVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.hasNavTVehicle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional eigen_lcm.Vector3fPb nav_t_waypoint = 4;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getNavTWaypoint = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 4));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
*/
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setNavTWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.clearNavTWaypoint = function() {
  return this.setNavTWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.hasNavTWaypoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float distance = 5;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float vehicle_pitch = 6;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getVehiclePitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setVehiclePitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float waypoint_pitch = 7;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getWaypointPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setWaypointPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float pitch_diff = 8;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getPitchDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setPitchDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float vehicle_heading = 9;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getVehicleHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setVehicleHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float waypoint_heading = 10;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getWaypointHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setWaypointHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float heading_diff = 11;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getHeadingDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setHeadingDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool has_reached_waypoint = 12;
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getHasReachedWaypoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setHasReachedWaypoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool has_reached_velocity_limit = 13;
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getHasReachedVelocityLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setHasReachedVelocityLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool ready_for_next_photo = 14;
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getReadyForNextPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setReadyForNextPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional float current_timeout_duration = 15;
 * @return {number}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getCurrentTimeoutDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setCurrentTimeoutDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional bool timed_out = 16;
 * @return {boolean}
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.getTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.StopForPhotoDebug} returns this
 */
proto.trajectory_action_planner.StopForPhotoDebug.prototype.setTimedOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointUuid: (f = msg.getWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
timedOut: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
linearVelocityLimitReached: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
angularVelocityLimitReached: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
timeSinceEnqueued: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
linearVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
angularVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
currentLinearVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
currentAngularVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug;
  return proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setWaypointUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTimedOut(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLinearVelocityLimitReached(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAngularVelocityLimitReached(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeSinceEnqueued(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLinearVelocityLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngularVelocityLimit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentLinearVelocity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentAngularVelocity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getTimedOut();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLinearVelocityLimitReached();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAngularVelocityLimitReached();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTimeSinceEnqueued();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLinearVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAngularVelocityLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getCurrentLinearVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCurrentAngularVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid waypoint_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
*/
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.clearWaypointUuid = function() {
  return this.setWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.hasWaypointUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool timed_out = 3;
 * @return {boolean}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setTimedOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool linear_velocity_limit_reached = 4;
 * @return {boolean}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getLinearVelocityLimitReached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setLinearVelocityLimitReached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool angular_velocity_limit_reached = 5;
 * @return {boolean}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getAngularVelocityLimitReached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setAngularVelocityLimitReached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float time_since_enqueued = 6;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getTimeSinceEnqueued = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setTimeSinceEnqueued = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float linear_velocity_limit = 7;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getLinearVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setLinearVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float angular_velocity_limit = 8;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getAngularVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setAngularVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float current_linear_velocity = 9;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getCurrentLinearVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setCurrentLinearVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float current_angular_velocity = 10;
 * @return {number}
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.getCurrentAngularVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug} returns this
 */
proto.trajectory_action_planner.EnqueuedPhotoCaptureRequestDebug.prototype.setCurrentAngularVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.PhotoCaptureStateDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.PhotoCaptureStateDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
photoRequestNonce: jspb.Message.getFieldWithDefault(msg, 1, 0),
photoFormat: jspb.Message.getFieldWithDefault(msg, 2, 0),
waypointUuid: (f = msg.getWaypointUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
requestUtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
captureUtime: jspb.Message.getFieldWithDefault(msg, 5, 0),
inProgressExposedUtime: jspb.Message.getFieldWithDefault(msg, 6, 0),
captureSuccessfulUtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
captureFailedUtime: jspb.Message.getFieldWithDefault(msg, 8, 0),
readyForNextUtime: jspb.Message.getFieldWithDefault(msg, 9, 0),
photoPath: jspb.Message.getFieldWithDefault(msg, 10, ""),
resultValid: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
result: jspb.Message.getFieldWithDefault(msg, 12, 0),
velocityLimitTimedOut: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
ispResultTimedOut: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.PhotoCaptureStateDebug;
  return proto.trajectory_action_planner.PhotoCaptureStateDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.PhotoCaptureStateDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhotoRequestNonce(value);
      break;
    case 2:
      var value = /** @type {!proto.user_camera.PhotoFormat.Enum} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    case 3:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setWaypointUuid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestUtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptureUtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInProgressExposedUtime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptureSuccessfulUtime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptureFailedUtime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReadyForNextUtime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoPath(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResultValid(value);
      break;
    case 12:
      var value = /** @type {!proto.user_camera.PhotoCaptureResult.Enum} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVelocityLimitTimedOut(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspResultTimedOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.PhotoCaptureStateDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.PhotoCaptureStateDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhotoRequestNonce();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPhotoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWaypointUuid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getRequestUtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCaptureUtime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInProgressExposedUtime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCaptureSuccessfulUtime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCaptureFailedUtime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getReadyForNextUtime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPhotoPath();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResultValid();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVelocityLimitTimedOut();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIspResultTimedOut();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int64 photo_request_nonce = 1;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getPhotoRequestNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setPhotoRequestNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional user_camera.PhotoFormat.Enum photo_format = 2;
 * @return {!proto.user_camera.PhotoFormat.Enum}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getPhotoFormat = function() {
  return /** @type {!proto.user_camera.PhotoFormat.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uuid_util.Uuid waypoint_uuid = 3;
 * @return {?proto.uuid_util.Uuid}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getWaypointUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 3));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
*/
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.clearWaypointUuid = function() {
  return this.setWaypointUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.hasWaypointUuid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 request_utime = 4;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getRequestUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setRequestUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 capture_utime = 5;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getCaptureUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setCaptureUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 in_progress_exposed_utime = 6;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getInProgressExposedUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setInProgressExposedUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 capture_successful_utime = 7;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getCaptureSuccessfulUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setCaptureSuccessfulUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 capture_failed_utime = 8;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getCaptureFailedUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setCaptureFailedUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 ready_for_next_utime = 9;
 * @return {number}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getReadyForNextUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setReadyForNextUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string photo_path = 10;
 * @return {string}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getPhotoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setPhotoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool result_valid = 11;
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getResultValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setResultValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional user_camera.PhotoCaptureResult.Enum result = 12;
 * @return {!proto.user_camera.PhotoCaptureResult.Enum}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getResult = function() {
  return /** @type {!proto.user_camera.PhotoCaptureResult.Enum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.user_camera.PhotoCaptureResult.Enum} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool velocity_limit_timed_out = 13;
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getVelocityLimitTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setVelocityLimitTimedOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool isp_result_timed_out = 14;
 * @return {boolean}
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.getIspResultTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug} returns this
 */
proto.trajectory_action_planner.PhotoCaptureStateDebug.prototype.setIspResultTimedOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.toObject = function(opt_includeInstance) {
  return proto.trajectory_action_planner.ActivePhotoCapturesDebug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
activeEoPhotoCapturesList: jspb.Message.toObjectList(msg.getActiveEoPhotoCapturesList(),
    proto.trajectory_action_planner.PhotoCaptureStateDebug.toObject, includeInstance),
activeIrPhotoCapturesList: jspb.Message.toObjectList(msg.getActiveIrPhotoCapturesList(),
    proto.trajectory_action_planner.PhotoCaptureStateDebug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trajectory_action_planner.ActivePhotoCapturesDebug;
  return proto.trajectory_action_planner.ActivePhotoCapturesDebug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.trajectory_action_planner.PhotoCaptureStateDebug;
      reader.readMessage(value,proto.trajectory_action_planner.PhotoCaptureStateDebug.deserializeBinaryFromReader);
      msg.addActiveEoPhotoCaptures(value);
      break;
    case 3:
      var value = new proto.trajectory_action_planner.PhotoCaptureStateDebug;
      reader.readMessage(value,proto.trajectory_action_planner.PhotoCaptureStateDebug.deserializeBinaryFromReader);
      msg.addActiveIrPhotoCaptures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trajectory_action_planner.ActivePhotoCapturesDebug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActiveEoPhotoCapturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.trajectory_action_planner.PhotoCaptureStateDebug.serializeBinaryToWriter
    );
  }
  f = message.getActiveIrPhotoCapturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.trajectory_action_planner.PhotoCaptureStateDebug.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} returns this
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PhotoCaptureStateDebug active_eo_photo_captures = 2;
 * @return {!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.getActiveEoPhotoCapturesList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.PhotoCaptureStateDebug, 2));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>} value
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} returns this
*/
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.setActiveEoPhotoCapturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.trajectory_action_planner.PhotoCaptureStateDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.addActiveEoPhotoCaptures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.trajectory_action_planner.PhotoCaptureStateDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} returns this
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.clearActiveEoPhotoCapturesList = function() {
  return this.setActiveEoPhotoCapturesList([]);
};


/**
 * repeated PhotoCaptureStateDebug active_ir_photo_captures = 3;
 * @return {!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.getActiveIrPhotoCapturesList = function() {
  return /** @type{!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trajectory_action_planner.PhotoCaptureStateDebug, 3));
};


/**
 * @param {!Array<!proto.trajectory_action_planner.PhotoCaptureStateDebug>} value
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} returns this
*/
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.setActiveIrPhotoCapturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.trajectory_action_planner.PhotoCaptureStateDebug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trajectory_action_planner.PhotoCaptureStateDebug}
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.addActiveIrPhotoCaptures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.trajectory_action_planner.PhotoCaptureStateDebug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trajectory_action_planner.ActivePhotoCapturesDebug} returns this
 */
proto.trajectory_action_planner.ActivePhotoCapturesDebug.prototype.clearActiveIrPhotoCapturesList = function() {
  return this.setActiveIrPhotoCapturesList([]);
};


goog.object.extend(exports, proto.trajectory_action_planner);
